
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Page } from '../../types/common'
import { OperationRecord } from '../../types/devices'

@Component
export default class Record extends Vue {
  @Prop() readonly deviceId!: string;
  page = 1
  size = 10
  total = 0
  loading = false
  tableData: OperationRecord[] = []

  created () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get<Page<OperationRecord>>(this.$apis.devices.getOperationRecords, {
      deviceId: this.deviceId,
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }
}
